import * as React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

import { ThemeContext } from "../contexts/Theme"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import "../styles/pages/contact.scss"

const ContactPage = ({ data }) => {
  const pageData = data.site.siteMetadata.pageData.contact

  const { device } = React.useContext(ThemeContext)

  const [formSubmitted, setFormSubmitted] = React.useState(false)
  const [formSuccess, setFormSuccess] = React.useState(null)
  const [display, setDisplay] = React.useState(false)

  const [fromEmail, setFromEmail] = React.useState("")
  const [name, setName] = React.useState("")
  const [content, setContent] = React.useState("")

  const nameRef = React.createRef(false)
  const emailRef = React.createRef(false)
  const subjectRef = React.createRef(false)
  const bodyRef = React.createRef(false)


  if (typeof window !== "undefined") {
    window.onSubmit = async () => {
      const data = {
        email: fromEmail,
        name,
        content,
      }

      try {
        const req = await fetch(`${process.env.GATSBY_FNS_ENDPOINT}/email`, {
          method: `POST`,
          headers: {
            Authorization: `Bearer ${process.env.GATSBY_SITE_KEY}`,
          },
          body: JSON.stringify(data),
        })

        console.log(req.ok, await req.text())

        window.setTimeout(() => setFormSuccess(req.ok), 1000)
      } catch (error) {
        console.log(error)
        window.setTimeout(() => setFormSuccess(false), 1000)
      }
    }
    window.onRecaptchaFailure = () =>
      window.setTimeout(() => {
        setFormSuccess(false)
      }, 1000)
  }

  const runRecaptcha = e => {
    e.preventDefault()

    if (emailRef.current && !emailRef.current.validity.valid) {
      console.log(emailRef, emailRef.current.validity)
      return
    }

    if (subjectRef.current && !subjectRef.current.validity.valid) {
      console.log(subjectRef, subjectRef.current.validity)
      return
    }

    if (bodyRef.current && !bodyRef.current.validity.valid) {
      console.log(bodyRef, bodyRef.current.validity)
      return
    }

    setFormSubmitted(true)

    if (typeof window !== "undefined" && window.grecaptcha) {
      try {
        window.grecaptcha.execute()
      } catch (error) {
        console.log(error)
        window.setTimeout(() => setFormSuccess(false), 1000)
      }
    }
  }

  let smallDevice = device === "xs" || device === "sm"

  React.useEffect(() => {
    if(typeof window  !== 'undefined' && typeof document !== 'undefined') {
      window.setTimeout(() => {
        setDisplay(true)
      },200)
    }
  },[])

  return (
    <>
      <Layout>
        <Seo title="Contact" page="contact" description="If you'd like to talk about an exciting project or would like to work with me, feel free to reach out to me"/>
        <Helmet>
          <script async src="https://www.google.com/recaptcha/api.js"></script>
        </Helmet>
        <div className="w-full">
          <div className="max-w-screen-2xl mx-auto min-h-screen-fill-content flex flex-col lg:flex-row relative">
            <div className="email flex items-center px-6 lg:flex-[6] lg:px-0 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full">
              <div className="relative w-full h-full flex items-center">
                <div
                  className={`form w-full lg:w-3/4  mx-auto ${
                    formSuccess !== null ? `form-processed` : ``
                  }`}
                >
                  <div className="header">
                    <div className={
                      `font-emberly-black-narrow `+
                      ` emb-lg xs:emb-xl ` +
                      `leading-[2.5rem] xs:leading-[2.8rem] sm:leading-[3.25rem] md:leading-[3.5rem] ` +
                      `uppercase text-center text-pop render-animation-container from-right ` + 
                      `${display ? `show` : ``}`
                    }>
                      Say Hi.
                    </div>
                    <div className={`russolo-sm md:russolo-base mt-2 md:mt-0 mb-4 w-full block text-primary-700 from-left render-animation-container ${display ? `show` : ``}`}>
                      {pageData.text}
                    </div>
                  </div>
                  <div className="wrapper">
                    <form>
                      <div className="form-container flex flex-col text-primary-100">
                        <div className="flex flex-col md:flex-row flex-1 w-full">
                          <div className="flex-1 flex">
                            <div className={
                              `input-container w-full pr-0 md:pr-2 render-animation-container ` +
                              `${smallDevice ? `from-right` : `minus-from-left`} ` + 
                              `${display ? `show` : ``}`
                            }>
                              <input
                                ref={nameRef}
                                className={
                                  `w-100 p-3 w-full text-pop outline-none bg-transparent duration-300 border border-primary-400 hover:border-primary-700 focus:border-pop ` +
                                  `russolo-sm md:russolo-base `
                                }
                                type="name"
                                id="name"
                                required
                                autoComplete="off"
                                placeholder="Name *"
                                onChange={event => setName(event.target.value)}
                              />
                            </div>
                          </div>
                          <div className="flex-1 flex">
                            <div className={
                              `input-container w-full pl-0 mt-4 md:mt-0 md:pl-2 render-animation-container ` + 
                              `${smallDevice ? `from-left` : `minus-from-right`} ` + 
                              `${display ? `show` : ``}`
                            }>
                              <input
                                ref={nameRef}
                                className={
                                  `w-100 p-3 w-full text-pop outline-none bg-transparent duration-300 border border-primary-400 hover:border-primary-700 focus:border-pop ` +
                                  `russolo-sm md:russolo-base`
                                }
                                type="email"
                                required
                                name="sendersEmail"
                                autoComplete="new-password"
                                placeholder="Email *"
                                onChange={event => setFromEmail(event.target.value)}
                              />
                              <span></span>
                            </div>
                          </div>
                        </div>
                        <div className="flex-1 flex w-full">
                          <div className={
                            `input-container w-full mt-4 render-animation-container ` + 
                            `${smallDevice ? `from-right` : `from-left`} ` + 
                            `${display ? `show` : ``}`
                          }>
                            <textarea
                              ref={bodyRef}
                              onChange={event => setContent(event.target.value)}
                              className={
                                `p-3 w-full h-48 font-russolo text-pop outline-none bg-transparent duration-300 ` +
                                `border border-primary-400 hover:border-primary-700 focus:border-pop ` +
                                `russolo-sm md:russolo-base `
                              }
                              placeholder="Content *"
                              required
                            ></textarea>
                          </div>
                        </div>
                        <div
                          className="g-recaptcha hidden"
                          data-sitekey={
                            process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY
                          }
                          data-callback="onSubmit"
                          data-error-callback="onRecaptchaFailure"
                          data-size="invisible"
                          data-badge="inline"
                        ></div>
                        <div className={
                          `w-full render-animation-container ` + 
                          `${smallDevice ? `from-left` : `from-right`} ` + 
                          `${display ? `show` : ``}`
                        }>
                          <button
                            className={
                              `submit-form mt-3 w-full relative font-emberly-black before:font-emberly-black uppercase text-primary-400 p-3 border ` +
                              `lg:transition-[border-color, color] bg-transparent border-primary-400 lg:duration-300 ` +
                              `text-xl xl:text-xl tracking-wide ` +
                              `lg:hover:border-pop lg:hover:text-pop ${
                                formSubmitted
                                  ? `submitted border-pop before:text-pop`
                                  : ``
                              }`
                            }
                            onClick={runRecaptcha}
                            type="submit"
                          >
                            <span className="block relative invisible">
                              {formSubmitted ? `Sending ...` : `Send`}
                            </span>
                          </button>
                        </div>
                          
                      </div>
                    </form>
                  </div>
                </div>
                <div
                  className={`server-response -z-10  w-full sm:w-3/4 lg:w-2/3 mx-auto absolute left-[50%] -translate-x-[50%] ${
                    formSuccess !== null ? `form-processed` : ``
                  }`}
                >
                  <div
                    className={`header w-full ${
                      formSuccess === true ? `block` : `hidden`
                    }`}
                  >
                    <div className={
                      `font-emberly-black-narrow text-pop uppercase w-full text-center `+
                      `text-[2.5rem] xs:text-[2.8rem] sm:text-[3.25rem] md:text-[3.5rem] xl:text-7xl ` +
                      `leading-[2.5rem] sm:leading-[3rem] md:leading-[4rem] lg:leading-[5rem] `
                    }>
                      Thanks.
                    </div>
                    <p className={
                      `font-russolo leading-loose text-center w-full block max-w-full ` + 
                      `tracking-wide font-black mt-3 sm:mt-4 text-primary-700 ` +
                      `desc-text`
                    }>
                      Thanks for reaching out to me! You'll be hearing from me
                      shortly.
                    </p>
                  </div>
                  <div
                    className={`header w-full ${
                      formSuccess === true ? `hidden` : `block`
                    }`}
                  >
                    <div className={
                      `font-emberly-black-narrow `+
                      `text-[2.5rem] xs:text-[2.8rem] sm:text-[3.25rem] md:text-[3.5rem] xl:text-7xl ` +
                      `leading-[2.5rem] sm:leading-[3rem] md:leading-[4rem] lg:leading-[5rem] ` +
                      `uppercase text-center text-red-error`
                    }>
                      Oops.
                    </div>
                    <p className={
                      `text-center w-full block max-w-full ` + 
                      `mt-3 sm:mt-4 text-primary-800 ` +
                      `desc-text`
                    }>
                      Looks like there was an issue while trying to process your
                      message. Please try again later.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        socials {
          ig
          linkedin
        }
        pageData {
          contact {
            text
          }
        }
        locationCity
      }
    }
  }
`

export default ContactPage
